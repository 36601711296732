<template lang="pug">
	div
		.row
			.col-xxl-4.order-1.order-xxl-2
				StatsWidget7
				StatsWidget12
			.col-xxl-8.order-1.order-xxl-2
				AdvancedTableWidget3
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import StatsWidget7 from "@/views/components/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/views/components/widgets/stats/Widget12.vue";
import AdvancedTableWidget3 from "@/views/components/widgets/advance-table/Widget3.vue";
export default {
	name: 'Dashboard',
	components: {
		StatsWidget7,
		StatsWidget12,
		AdvancedTableWidget3
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
	},
}
</script>
